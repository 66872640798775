import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './page/HomePage';
import CountdownPage from './page/CountdownPage';

const App: React.FC = () => {

  return (
    <div className="App text-secondary bg-black bg-opacity-80">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/countdown" element={<CountdownPage targetDate="2024-10-06T07:59:59" />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
