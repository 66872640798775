import React from 'react';

const Home: React.FC = () => {
  return (
    <section id="home" className="text-white text-center mt-20 py-20 px-4 bg-opacity-20 bg-gray-900 h-[60vh] flex justify-center content-center">
      <div className="max-w-4xl mx-auto p-8 rounded-lg shadow-2xl my-auto">
        <a href="#signup">
          <button className="bg-primary min-w-[50%] text-white text-3xl font-semibold px-6 py-3 rounded hover:shadow-md focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-opacity-50 hover:scale-110 transition-transform duration-300">
            Seja Voluntário
          </button>
        </a>
        <h2 className="text-4xl font-extrabold mt-6 mb-6">Clique no botão acima e ajude a combater o PT em Fortaleza</h2>        
      </div>
    </section>
  );
};

export default Home;