import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import carimbo from '../assets/carimbo.png';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-primary text-secondary p-4 shadow-lg fixed top-0 left-0 right-0 z-50">
      <div className="flex justify-between items-center mx-8">
        <img src={carimbo} alt="Logo" className="h-20 mx-8" />
        
        {/* Hamburger Menu Icon */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isMenuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
          </button>
        </div>

        {/* Normal Navigation Menu */}
        <nav className="hidden md:flex flex-row space-x-4">
          <ul className="flex space-x-4">
            <li><a href="#home" className="hover:underline text-white transition duration-300 ease-in-out">Início</a></li>
            <li><a href="#past-actions" className="hover:underline text-white transition duration-300 ease-in-out">Ações</a></li>
            <li><a href="#signup" className="hover:underline text-white transition duration-300 ease-in-out">Cadastre-se</a></li>
            <li><a href="#donate" className="hover:underline text-white transition duration-300 ease-in-out">Doe</a></li>
            <li><a href="#contact" className="hover:underline text-white transition duration-300 ease-in-out">Contato</a></li>
          </ul>
        </nav>
      </div>

      {/* Mobile Navigation Menu */}
      {isMenuOpen && (
        <nav className="md:hidden bg-primary text-secondary p-4 shadow-lg">
          <ul className="flex flex-col space-y-4">
            <li><a href="#home" className="hover:underline text-white transition duration-300 ease-in-out" onClick={toggleMenu}>Início</a></li>
            <li><a href="#past-actions" className="hover:underline text-white transition duration-300 ease-in-out" onClick={toggleMenu}>Ações</a></li>
            <li><a href="#signup" className="hover:underline text-white transition duration-300 ease-in-out" onClick={toggleMenu}>Cadastre-se</a></li>
            <li><a href="#donate" className="hover:underline text-white transition duration-300 ease-in-out" onClick={toggleMenu}>Doe</a></li>
            <li><a href="#contact" className="hover:underline text-white transition duration-300 ease-in-out" onClick={toggleMenu}>Contato</a></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
