import React, { useState, FormEvent } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha"

interface FormData {
    nome: string;
    email: string;
    telefone: string;
    bairro: string;
    cidade: string;
    tiposDeAcoes: {
        rua: boolean;
        online: boolean;
    };
}

const SignUp: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        nome: '',
        email: '',
        telefone: '',
        bairro: '',
        cidade: '',
        tiposDeAcoes: {
            rua: false,
            online: false,
        },
    });

    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;
        if (type === 'checkbox') {
            const target = e.target as HTMLInputElement;
            const checked = target.checked;
            setFormData({
                ...formData,
                tiposDeAcoes: {
                    ...formData.tiposDeAcoes,
                    [name]: checked,
                },
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleCaptcha = (token: string | null) => {
        setCaptchaToken(token);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (!captchaToken) {
            setError('Por favor, verifique o CAPTCHA.');
            setLoading(false);
            return;
        }

        try {
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("telefone", "==", formData.telefone));

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                setError('Número de telefone já cadastrado.');
                setLoading(false);
                return;
            }

            await addDoc(collection(db, "users"), { ...formData });

            alert('Sucesso!');
            setFormData({
                nome: '',
                email: '',
                telefone: '',
                bairro: '',
                cidade: '',
                tiposDeAcoes: {
                    rua: false,
                    online: false,
                },
            });
        } catch (error) {
            console.error("Error adding document: ", error);
            setError('Erro ao cadastrar. Tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section id="signup" className="py-10 bg-opacity-20 bg-gray-900 flex justify-center items-center">
            <div className="container mx-auto shadow-lg rounded-lg overflow-hidden w-full max-w-md">
                <div className="px-8 py-10">
                    <h2 className="text-3xl font-bold mb-6 text-secondary text-center">Cadastre-se</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block mb-2 text-gray-200">Nome:</label>
                            <input
                                type="text"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                                className="text-black w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-gray-200">Telefone:</label>
                            <input
                                type="tel"
                                name="telefone"
                                value={formData.telefone}
                                onChange={handleChange}
                                className="text-black w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-gray-200">Bairro:</label>
                            <input
                                type="text"
                                name="bairro"
                                value={formData.bairro}
                                onChange={handleChange}
                                className="text-black w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-gray-200">Cidade:</label>
                            <input
                                type="text"
                                name="cidade"
                                value={formData.cidade}
                                onChange={handleChange}
                                className="text-black w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-gray-200">E-mail:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="text-black w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-gray-200">Como você derrotará o PT:</label>
                            <div className="flex flex-col space-y-2">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        name="rua"
                                        checked={formData.tiposDeAcoes.rua}
                                        onChange={handleChange}
                                        className="form-checkbox rounded text-primary focus:ring-primary"
                                    />
                                    <span className="ml-2 text-gray-200">Ações de Rua</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        name="online"
                                        checked={formData.tiposDeAcoes.online}
                                        onChange={handleChange}
                                        className="form-checkbox rounded text-primary focus:ring-primary"
                                    />
                                    <span className="ml-2 text-gray-200">Ações Online</span>
                                </label>
                            </div>
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY || ''}
                            onChange={handleCaptcha}
                        />
                        <button
                            type="submit"
                            className="bg-primary text-white font-semibold px-6 py-3 rounded hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-opacity-50 w-full flex justify-center hover:scale-110 transition-transform duration-300"
                            disabled={loading}
                        >
                            {error && <p className="text-red-500">{error}</p>}
                            {loading ? 'Enviando...' : 'Enviar'}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default SignUp;
