import React from 'react';
import Home from '../components/Home';
import SignUp from '../components/SignUp';
import Contact from '../components/Contact';
import Donate from '../components/Donate';
import PastActions from '../components/PastActions';

const HomePage: React.FC = () => {
    return (
        <main>
            <Home />
            <PastActions />
            <SignUp />
            <Donate />
            <Contact />
        </main>
    );
};

export default HomePage;