import React from 'react';
import elmano from '../assets/elmano_insegurança.jpeg';
import manifestacao from '../assets/manifestacao_uber.jpeg';

const PastActions: React.FC = () => {
    const actions = [
        { imgSrc: manifestacao, description: 'Manifestação de motorista de aplicativo contra a PL' },
        { imgSrc: elmano, description: 'Expor Elmano (PT) e sua ingerência com a segurança do estado' },
    ];

    const cardStyle = {
        animation: 'fadeIn 1s ease-in-out'
    };
    
    const fadeInKeyframes = `
        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: translateY(50px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    `;

    return (
        <section id="past-actions" className="text-center py-20 px-4 bg-opacity-20 bg-primary min-h-[40vh] flex justify-center content-center">
            <div className="max-w-4xl mx-auto my-auto p-8 rounded-lg shadow-2xl">
                <style>{fadeInKeyframes}</style>
                <h2 className="text-white text-3xl font-bold mb-4">Ações Já Feitas</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                    {actions.map((action, index) => (
                        <div key={index} className="bg-gray-200 p-4 rounded shadow-md hover:scale-110 transition-transform duration-300" style={cardStyle}>
                            <img src={action.imgSrc} alt={`Ação ${index + 1}`} className="w-full rounded " />
                            <p className="text-black mt-2 self-center">{action.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PastActions;
