import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-primary text-secondary text-center py-4">
            <p>&copy; 2024 Não deixe o PT dominar. Todos os direitos reservados.</p>
        </footer>
    );
};

export default Footer;
