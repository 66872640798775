import React from 'react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

const Contact = () => {
    return (
        <section id="contact" className="py-10 bg-opacity-20 bg-gray-900 h-[40vh] flex justify-center content-center">
            <div className="container mx-auto my-auto">
                <h2 className="text-3xl font-bold mb-4 text-center">Onde você nos encontra?</h2>
                <div className="mt-4 flex flex-row justify-center space-x-4 mx-auto">
                    <a href="https://whatsapp.com/channel/0029VaiIKfC4tRrkCT2qCw2D" className="text-white text-2xl hover:scale-110 transition-transform duration-300">
                        <FaWhatsapp size={54}/>
                    </a>
                    <a href="https://www.instagram.com/ptnaoce" className="text-white text-2xl hover:scale-110 transition-transform duration-300">
                        <FaInstagram size={54}/>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Contact;