import React from 'react';
import imgSrc from '../assets/pix.png';

const Donate = () => {
    return (
        <section id="donate" className="py-10 bg-opacity-20 bg-primary min-h-[40vh] flex justify-center content-center">
            <div className="container mx-auto my-auto max-w-[90vw]">
                <h2 className="text-3xl font-bold mb-4 text-center">Nos Ajude</h2>
                <div className="flex flex-row text-center justify-center content-center space-x-8">
                    <h2 className="text-2xl font-bold mb-4 justify-center content-center">Doe para nos ajudar</h2>
                    <img src={imgSrc} alt={`PIX`} className="rounded h-[25vh] w-[25vh] " />
                </div>
            </div>
        </section>
    );
};

export default Donate;