import React, { useEffect, useState } from 'react';

interface CountdownProps {
  targetDate: string;
}

const CountdownPage: React.FC<CountdownProps> = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="h-screen flex items-center justify-center bg-black text-white text-center text-2xl">
      <div>
        <h1 className="text-5xl m-8">Dia da Eleição</h1>
        <div className="flex justify-center md:space-x-8 flex-col md:flex-row">
          <div className="text-center">
            <span className="block text-6xl font-bold">{timeLeft.days}</span>
            <span className="text-xl">{timeLeft.days != 1 ? 'Dias' : 'Dia'}</span>
          </div>
          <div className="text-center">
            <span className="block text-6xl font-bold">{timeLeft.hours}</span>
            <span className="text-xl">
              {timeLeft.hours != 1 ? 'Horas' : 'Hora'}
            </span>
          </div>
          <div className="text-center">
            <span className="block text-6xl font-bold">{timeLeft.minutes}</span>
            <span className="text-xl">
              {timeLeft.minutes != 1 ? 'Minutos' : 'Minuto'}
            </span>
          </div>
          <div className="text-center">
            <span className="block text-6xl font-bold">{timeLeft.seconds}</span>
            <span className="text-xl">
              {timeLeft.seconds != 1 ? 'Segundos' : 'Segundo'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownPage;
